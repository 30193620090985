<template>
  <div class="card card--profile">
    <div class="card--profile__img">
      <div :style="profileImgStyle(profile.additionalInfos.images)"></div>
    </div>
    <div class="card--profile__user">
      <span>{{profile.additionalInfos.company.companyName}}</span>
      <span>{{profile.labelRelationType}}</span>
    </div>
    <div class="card--profile__action">
      <b-button
        variant="info"
        @click="changeProfile(profile)"
        size="sm"
        v-if="isOperative"
      >
        <font-awesome-icon icon="eye" />
      </b-button>
      <b-button
        variant="warning"
        @click="activateProfile(profile)"
        size="sm"
        v-if="!isOperative"
      >
        <font-awesome-icon icon="check" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { createPublicUri } from '@/utils/utilities';

export default {
  name: 'UserCompanyRow',
  props: {
    profile: Object,
    closeModal: Function,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      paymentFailed: false,
    };
  },
  computed: {
    isOperative() {
      return this.profile.status === 'ACTIVE';
    },
  },
  methods: {
    profileImgStyle(img) {
      const imgUrl = img.length
        ? createPublicUri(img[0].documentUpload.externalId)
        : this.userPlaceholder;
      return `background-image: url(${imgUrl});`;
    },
    changeProfile() {
      console.debug('UserCompanyRow changeprofile setpreloader', true);
      this.$store.dispatch('setPreloader', true);

      this.$store.dispatch('subject/setCurrentProfile', this.profile).then(() => {
        console.debug('UserCompanyRow loaded setpreloader', false);
        this.$store.dispatch('setPreloader', false);
        // this.routeTo('/dashboard-utente');
      },
      (error) => {
        console.log('Error in change profile', error);
        console.debug('UserCompanyRow error setpreloader', false);
        this.$store.dispatch('setPreloader', false);
      });

      if (this.closeModal) this.closeModal();
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    activateProfile() {
      if (this.isOperative) return;
      this.$confirm(
        {
          message: `Vuoi accettare la richiesta di collaborazione per la società ${this.profile.additionalInfos.company.companyName}?`,
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
          callback: (confirm) => {
            if (confirm) {
              const { relationId } = this.profile;
              this.$store.dispatch('subject/acceptInvitation', { relationId, entityType: 'person' });
            }
          },
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
